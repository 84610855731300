import React, { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const AddCar = () => {
    const navigate = useNavigate();
  const { userId } = useParams(); 
  const user = JSON.parse(localStorage.getItem("user"))
  const [formData, setFormData] = useState({
    make: '',
    model: '',
    year: '',
    price: '',
    description: '',
    carImage: '',
    sellerID: user._id
  
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/cars`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to add car');
      }

      navigate('/myprofile');
    } catch (error) {
      console.error('Error adding car:', error);
    }
  };

  return (
    <div className="add-car-page">
      <header className="header">
        <div className="menu">
          <div className="menu-left">
            <div className="project-info">
              <img src="assets\logo.svg" alt="WheelWish Logo" className="logo" />
            </div>
          </div>
          <div className="menu-right">
            <Link to="/" className="home-button">
              <FontAwesomeIcon icon={faHome} className="home-icon" />
            </Link>
          </div>
        </div>
      </header>

      <div className="add-car-content">
        <h2>Add New Car</h2>
        <h3>Let's add a shiny new car to your listing!</h3>
        <form className="add-car-form" onSubmit={handleSubmit}>
        <div className="form-group">
            <label htmlFor="make">Make:</label>
            <input type="text" id="make" name="make" value={formData.make} onChange={handleChange} />
          </div>

          <div className="form-group">
            <label htmlFor="model">Model:</label>
            <input type="text" id="model" name="model" value={formData.model} onChange={handleChange} />
          </div>

          <div className="form-group">
            <label htmlFor="year">Year:</label>
            <input type="number" id="year" name="year" value={formData.year} onChange={handleChange} />
          </div>

          <div className="form-group">
            <label htmlFor="price">Price:</label>
            <input type="number" id="price" name="price" value={formData.price} onChange={handleChange} />
          </div>

          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea id="description" name="description" value={formData.description} onChange={handleChange} />
          </div>

          <div className="form-group">
            <label htmlFor="carImage">Car Image URL:</label>
            <input type="text" id="carImage" name="carImage" value={formData.carImage} onChange={handleChange} />
          </div>

          <button type="submit">Add Car</button>
          <button className="back-to-profile" onClick={() => window.location.href = '/myprofile'}>Back</button>

        </form>
      </div>
      <footer className="footer">
        <div className="footer-content">
          <p>&copy; 2023 WheelWish. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default AddCar;
