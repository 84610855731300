import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const SignUp = () => {
    const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to sign up');
      }
      

      navigate('/signin');
      console.log('Signup successful!');
    } catch (error) {
      console.error('Error during signup:', error);
    }
  };

  return (
    <div className="signup-page">
       <header className="header">
        <div className="menu">
          <div className="menu-left">
            <div className="project-info">
              <img src="assets\logo.svg" alt="WheelWish Logo" className="logo" />
            </div>
          </div>
          <div className="menu-right">
            {/* Add home button with an icon */}
            <Link to="/" className="home-button">
              <FontAwesomeIcon icon={faHome} className="home-icon" />
            </Link>
            <div className="menu-link">
              <Link to="/signin">Sign In</Link>
            </div>
          </div>
        </div>
      </header>
      <h3>Unlock possibilities, sign up on Wheelwish today!</h3>
      <form className="signup-form" onSubmit={handleSignUp}>
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone:</label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Sign Up</button>

      </form>
      <footer className="footer">
        <div className="footer-content">
          <p>&copy; 2023 WheelWish. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default SignUp;
