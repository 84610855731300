import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPlus } from '@fortawesome/free-solid-svg-icons';

const ShoppingCart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(storedCartItems);
    calculateTotalPrice(storedCartItems);
  }, []);

  const removeFromCart = (carId) => {
    const updatedCart = cartItems.filter((item) => item._id !== carId);
    updateCart(updatedCart);
  };

  const updateCart = (updatedCart) => {
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    calculateTotalPrice(updatedCart);
  };

  const calculateTotalPrice = (items) => {
    const total = items.reduce((sum, item) => sum + (parseFloat(item.price) || 0), 0);
    setTotalPrice(total);
  };

  const goBackToHomePage = () => {
    navigate('/');
  };

  const handleSignOut = () => {
  
    localStorage.removeItem('token'); 
    navigate('/signin'); 
    window.location.reload()
  };

  return (
    <div className="shopping-cart">
       <header className="header">
        <div className="menu">
          <div className="menu-left">
            <div className="project-info">
              <img src="assets\logo.svg" alt="WheelWish Logo" className="logo" />
            </div>
          </div>
          <div className="menu-right">
          <Link to="/" className="home-button">
              <FontAwesomeIcon icon={faHome} className="home-icon" />
            </Link>
            <div className="menu-link">
              <Link to="/myprofile">My Profile</Link>
            </div>
            <button type="button" className="sign-out-button" onClick={handleSignOut}>
              Sign Out
            </button>
          </div>
        </div>
      </header>
      <h2>Shopping Cart</h2>
      {cartItems.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <div className="all-car-item">
          <p>Due to high demand, each customer are limited to acquiring just one version of each car.</p>
          {cartItems.map((item) => (
            <div className="car-item" key={item._id}>
              <img src={item.carImage} alt={`${item.make} ${item.model}`} className="car-image2" />
              <div>
                <h3>{`${item.make} ${item.model}`}</h3>
                <p>Price: ${item.price}</p>
                <button onClick={() => removeFromCart(item._id)}>Remove from Cart</button>
              </div>
            </div>
          ))}
    
          <div className="total-price">
            <h3>Total Price: ${totalPrice.toFixed(2)}</h3>
          </div>
        </div>
      )}

      <div className="shopping-cart-button">
        <Link to="/checkout" className="checkout-button">
          Proceed to Checkout
        </Link>
        <div className="checkout to homepage">
          <button onClick={goBackToHomePage}>Go Back to Homepage</button>
        </div>
      </div>
      <footer className="footer">
        <div className="footer-content">
          <p>&copy; 2023 WheelWish. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default ShoppingCart;
