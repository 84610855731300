import React from 'react';
import ReactModal from 'react-modal';



const EditCarModal = ({ isOpen, onRequestClose, handleEditCarChange, handleEditCarSubmit, editedCar}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Edit Car Modal"
      className="edit-car-modal"
    >
      <h2>Edit Car</h2>
      <form className="edit-car-form" onSubmit={handleEditCarSubmit}>
        <div>
          <label htmlFor="make">Make:</label>
          <input
            type="text"
            id="make"
            name="make"
            value={editedCar.make}
            onChange={handleEditCarChange}
          />
        </div>

        <div>
          <label htmlFor="model">Model:</label>
          <input
            type="text"
            id="model"
            name="model"
            value={editedCar.model}
            onChange={handleEditCarChange}
          />
        </div>

        <div>
          <label htmlFor="year">Year:</label>
          <input
            type="number"
            id="year"
            name="year"
            value={editedCar.year}
            onChange={handleEditCarChange}
          />
        </div>

        <div>
          <label htmlFor="price">Price:</label>
          <input
            type="number"
            id="price"
            name="price"
            value={editedCar.price}
            onChange={handleEditCarChange}
          />
        </div>

        <div>
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            name="description"
            value={editedCar.description}
            onChange={handleEditCarChange}
          />
        </div>

        <div>
          <label htmlFor="carImage">Car Image:</label>
          <input
            type="text"
            id="carImage"
            name="carImage"
            value={editedCar.carImage}
            onChange={handleEditCarChange}
          />
        </div>

        <button type="submit" className="edit-car-modal-save" >Save Changes</button>
        <button type="button" className="edit-car-modal-close" onClick={onRequestClose}>
        Close
      </button>
   
      </form>

    </ReactModal>
  );
};

export default EditCarModal;
