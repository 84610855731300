import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import ProductList from './ProductList';

const HomePage = () => {
  const [carListings, setCarListings] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/cars`)
      .then((response) => response.json())
      .then((data) => {
     
        // Combine fetched data with fake cars
        setCarListings(data);
      })
      .catch((error) => console.error('Error fetching car listings:', error));
  }, []);

  return (
    <div className="home-page">
      <header className="header">
        <div className="menu">
          <div className="menu-left">
            <div className="project-info">
              <img src="assets\logo.svg" alt="WheelWish Logo" className="logo" />
            </div>
          </div>
          <div className="menu-right">
            <div className="menu-link">
              <Link to="/myprofile">My Profile</Link>
            </div>
            <div className="menu-link">
              <Link to="/signin">Sign In</Link>
            </div>
            <div className="menu-link">
            <Link to="/shopping-cart">
              <FontAwesomeIcon icon={faShoppingCart} />
              <span className="cart-label">Cart</span>
            </Link>
            </div>
          </div>
        </div>
      </header>
      <div className="home-content-title">
          <h1>Explore Our Collection</h1>
          <h2>Turning Your Auto Dreams into Reality</h2>
      </div>
      <ProductList carListings={carListings} />
      <footer className="footer">
        <div className="footer-content">
          <p>&copy; 2023 WheelWish. All rights reserved.</p>
        </div>
      </footer>
    </div>
    
  );
};

export default HomePage;