import React from 'react';
import { useNavigate } from 'react-router-dom';

const Checkout = () => {
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Logic for submission (fake, as no real submission will happen)
    alert('Payment successful!');
    navigate('/'); // Redirect to home page or confirmation page after submission
  };
  const goToShoppingCart = () => {
    navigate('/shopping-cart');
  };


  return (
    <div className="checkout-page">
      <h2>Checkout</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="cardNumber">Card Number:</label>
          <input type="text" id="cardNumber" name="cardNumber" required />
        </div>

        <div className="form-group">
          <label htmlFor="cardName">Name on Card:</label>
          <input type="text" id="cardName" name="cardName" required />
        </div>

        <div className="form-group">
          <label htmlFor="expiryDate">Expiry Date:</label>
          <input type="text" id="expiryDate" name="expiryDate" placeholder="MM/YY" required />
        </div>

        <div className="form-group">
          <label htmlFor="cvv">CVV:</label>
          <input type="text" id="cvv" name="cvv" required />
        </div>
      </form>
      <div  className="checkout-buttons">
      <button type="submit" className="submit-button">Pay Now</button>
      <button onClick={goToShoppingCart} className="back-to-cart-button">Go Back to ShoppingCart</button>
      </div>
    </div>
  );
};

export default Checkout;
