import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';

const ProductDetail = () => {
  const [carDetails, setCarDetails] = useState(null);
  const { carId } = useParams();

  useEffect(() => {
    const fetchCarDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/cars/${carId}`);

        if (!response.ok) {
          throw new Error('Failed to fetch car details');
        }

        const data = await response.json();
        console.log('this is data', data);
        setCarDetails(data);
      } catch (error) {
        console.error('Error fetching car details:', error);
      }
    };

    fetchCarDetails();
  }, [carId]);

  if (!carDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="product-detail-container">
      <header className="header">
        <div className="menu">
          <div className="menu-left">
            <div className="project-info">
              <img src="\assets\logo.svg" alt="WheelWish Logo" className="logo" />
            </div>
          </div>
          <div className="menu-right">
            <div className="menu-link">
              <Link to="/myprofile">My Profile</Link>
            </div>
            <div className="menu-link">
              <Link to="/signin">Sign In</Link>
            </div>
            <div className="menu-link">
            <Link to="/shopping-cart">
              <FontAwesomeIcon icon={faShoppingCart} />
              <span className="cart-label">Cart</span>
            </Link>
            </div>
          </div>
        </div>
      </header>
      <h1 className="product-detail-heading">{carDetails.make} {carDetails.model} Details</h1>
      <h3 className="product-detail-description">
        Explore comprehensive details about the features, specifications, and design of the {carDetails.make} {carDetails.model}.
      </h3>
      <img src={carDetails.carImage} alt={`${carDetails.make} ${carDetails.model}`} className="car-image" />

      <div className="product-specs">
        <p><strong>Year:</strong> {carDetails.year}</p>
        <p><strong>Price:</strong> ${carDetails.price}</p>
        <p><strong>Description:</strong> {carDetails.description}</p>
      </div>

      <button className="back-to-list-button" onClick={() => window.location.href = '/'}>
        <i className="fas fa-arrow-left"></i> Back to Car List
      </button>

      <footer className="footer">
        <div className="footer-content">
          <p>&copy; 2023 WheelWish. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default ProductDetail;
