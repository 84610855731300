import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const SignIn = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        console.error('Authentication failed');
        return;
      }
  
      const { user, token } = await response.json();

      localStorage.setItem('token', token)
      localStorage.setItem('user', JSON.stringify(user))

      navigate('/myprofile');
      window.location.reload()
    } catch (error) {
      console.error('Error during sign-in:', error);

    }
  };
  
  return (
    <div className="sign-in-page">
      <header className="header">
        <div className="menu">
          <div className="menu-left">
            <div className="project-info">
              <img src="assets\logo.svg" alt="WheelWish Logo" className="logo" />
            </div>
          </div>
          <div className="menu-right">
            {/* Add home button with an icon */}
            <Link to="/" className="home-button">
              <FontAwesomeIcon icon={faHome} className="home-icon" />
            </Link>
          </div>
        </div>
      </header>

      <div className="content">
        <h2>Welcome Back!</h2>
        <h4>It's great to have you with us again.</h4>
        <h2>Sign In</h2>
        <form className="login-form" onSubmit={handleSignIn}>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </div>
          <button type="submit">Sign In</button>
        </form>
        <p className='account-signup-suggest'>
          Don't have an account? <Link to="/signup">Sign up</Link>.
        </p>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <p>&copy; 2023 WheelWish. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default SignIn;
