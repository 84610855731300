import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ProductDetail from './ProductDetail';

const ProductList = ({ carListings }) => {
  const navigate = useNavigate();

  const addToCart = (car) => {
    let cart = JSON.parse(localStorage.getItem('cart')) || [];
    if (!cart.some(item => item._id === car._id)) {
      cart.push(car);
      localStorage.setItem('cart', JSON.stringify(cart));
      alert('Added to cart!');
    } else {
      alert('Due to high demand, each customer are limited to acquiring just one version of each car.');
    }
  };

  return (
    <div className="car-listings">
      {carListings.map((car) => (
        <div key={car._id} className="car-listing">
          <img src={car.carImage} alt={`${car.make} ${car.model}`} className="car-image" />
          <div className="car-info">
            <h3>{`${car.make} ${car.model}`}</h3>
            <p>Price: ${car.price}</p>
            <div className='car-info-buttons'>
              <button onClick={() => navigate(`/product-details/${car._id}`)}>View Details</button>
              <button onClick={() => addToCart(car)}>Add to Cart</button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductList;