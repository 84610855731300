import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPlus } from '@fortawesome/free-solid-svg-icons';
import EditCarModal from './EditModal';


const MyProfile = () => {
  const [myCarListings, setMyCarListings] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editedCar, setEditedCar] = useState({});
  const [updated, setUpdated] = useState(0)
  const navigate = useNavigate();


  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const fetchMyCarListings = async () => {
      const token = localStorage.getItem('token');
      console.log('User ID:', user._id);


  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/cars/user/${user._id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch my car listings');
        }

        const data = await response.json();
        setMyCarListings(data);
      } catch (error) {
        console.error('Error fetching user car listings:', error);
      }
    };

    fetchMyCarListings();
  }, [updated]);

  const handleDeleteAccount = async () => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    try {

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/${user._id}`, {
        
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete account');
      }
     

      console.log('User account deleted successfully');
  
      navigate('/signin');
    } catch (error) {
      console.error('Error deleting user account:', error);
 
    }
  };
  

  const handleSignOut = () => {
  
    localStorage.removeItem('token'); 
    navigate('/signin'); 
    window.location.reload()
  };

  const handleEditCarChange = (e) => {
    const { name, value } = e.target;
    setEditedCar((prevCar) => ({
      ...prevCar,
      [name]: value,
    }));
  };
  

  const handleEditCarSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting edited car:', editedCar);
  
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/cars/${editedCar._id}`, {
        method: 'PUT', 
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(editedCar),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update car');
      }
  
      console.log('Car updated successfully');
      setUpdated(prev => prev+1)
      setEditModalOpen(false); 
    } catch (error) {
      console.error('Error updating car:', error);
    }
  };
  
  const handleEditModalOpen = (car) => {
    const fetchedCarDetails = car;
    setEditedCar(fetchedCarDetails);
    setEditModalOpen(true);
  };
  
  

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };
  const handleDeleteCar = async (carID) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/cars/${carID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete car');
      }

      console.log('Car deleted successfully');

      setMyCarListings((prevListings) => prevListings.filter((car) => car._id !== carID));
    } catch (error) {
      console.error('Error deleting car:', error);
    }
  }

  return (
    <div className="my-profile-page">
      <header className="header">
        <div className="menu">
          <div className="menu-left">
            <div className="project-info">
              <img src="assets\logo.svg" alt="WheelWish Logo" className="logo" />
            </div>
          </div>
          <div className="menu-right">
            <Link to="/" className="home-button">
              <FontAwesomeIcon icon={faHome} className="home-icon" />
            </Link>
              {/* Sign-out button */}
            <button type="button" className="sign-out-button" onClick={handleSignOut}>
              Sign Out
            </button>
          </div>
        </div>
      </header>

      <div className="content">
        {/* My profile greeting message */}
        <div className="my-profile-greeting-msg" >
          <h2>Welcome to your profile, {user.name}!</h2>
          <p>
            Here, you can manage your car listings. Feel free to create new listings,
            edit your existing ones, or, if you ever decide, delete your account.
          </p>
        </div>
        {/* My Listings section */}
        <div className="my-listings-container">
          <div className="my-listings-header">
            <h2>My Listings</h2>
            {/* Button to add a new car */}
            <Link to="/AddCar" className="add-car-button">
              <FontAwesomeIcon icon={faPlus} className="add-car-icon" />
              Add New Car
            </Link>
          </div>
          <div className="my-listings-box">
            {myCarListings.map((car) => (
              <div key={car._id} className="car-listing2">
                {/* Car picture */}
                <img src={car.carImage} alt={`${car.make} ${car.model}`} className="car-image2" style={{ maxWidth: '400px' }}/>
                {/* Car details */}
                <div className="car-info2">
                  <h3>{`${car.make} ${car.model}`}</h3>
                  <p>Year: {car.year}</p>
                  <p>Price: ${car.price}</p>
                  <p>Description: {car.description}</p>
                  {/* Edit and delete buttons */}
                  <button type="button" className="modify-listing-button" onClick={() => handleEditModalOpen(car)}>Edit</button>
                  <button type="button"className="modify-listing-button-delete" onClick={() => handleDeleteCar(car._id)}>Delete</button>
                </div>
              </div>
            ))}
          </div>
        </div>
          {/* Delete Account button */}
          <button type="button" className="delete-account-button" onClick={handleDeleteAccount}>
            Delete Account
          </button>
      </div>
      <footer className="footer">
        <div className="footer-content">
          <p>&copy; 2023 WheelWish. All rights reserved.</p>
        </div>
      </footer>

      <EditCarModal
        isOpen={isEditModalOpen}
        onRequestClose={handleEditModalClose}
        handleEditCarChange={handleEditCarChange}
        handleEditCarSubmit={handleEditCarSubmit}
        editedCar={editedCar}
      />

    </div>
  );
};

export default MyProfile;
