import React from 'react';
import './App.css';
import HomePage from './components/HomePage';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import MyProfile from './components/MyProfile';
import ProductDetail from './components/ProductDetail'; 
import ShoppingCart from './components/ShoppingCart'; 
import AddCar from './components/AddCar';
import Checkout from './components/Checkout';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';


function App() {
  const isLoggedIn = ()=> {
    const isAuthenticated = localStorage.getItem('token'); 
    return isAuthenticated
  }
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/myprofile" element={ isLoggedIn() ?  <MyProfile /> : <Navigate to = "/signin"/> } />
        <Route
          path="/product-details/:carId"
          element={<ProductDetail />}
        />
        <Route path="/shopping-cart" element={ isLoggedIn() ?  <ShoppingCart /> : <Navigate to = "/signin"/> } />
        <Route path="/addcar" element={ isLoggedIn() ?  <AddCar /> : <Navigate to = "/signin"/> } />
        <Route path="/checkout" element={<Checkout />} /> {/* This line uses the Checkout component */}
      </Routes>
    </Router>
  );
}

export default App;